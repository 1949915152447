import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { BsGear, BsArchive } from "react-icons/bs";
import { Trans, t } from "@lingui/macro";
import PulseLoader from "react-spinners/PulseLoader";

import { fetchUsers, addUser, updateUser, deleteUser } from '../store/userSlice';
import { fetchProjects } from '../store/projectSlice';

import StyledButton from '../components/UI/StyledButton/StyledButton';

import './Settings.css';
import Modal from '../components/Modal';

const UserRow = ({ user, onEdit, onDelete }) => {
    const { id, email, userProjectsIds, role } = user;
    const { list } = useSelector(state => state.projects);
    //const isSuperAdmin = user.email === 'taulean@pfbc.md' ? true : false;

    const SecondRow = () => {
        return (
            <tr>
                <td colSpan={3}>
                    <div className='project-wrapper'>
                        {userProjectsIds && userProjectsIds.map((projectId) => {
                            //console.log(userProjectsIds, projectId, list);
                            const projectFound = list.find(e => e.id === projectId);
                            //console.log(projectFound);
                            return (
                                <div className='project' key={projectId + "-" + id}>
                                    {projectFound && projectFound.name}
                                </div>
                            );
                        })}
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <>
            <tr>
                <td style={role === 1 ? {borderBottom: 'none' } : { }}>{id}</td>
                <td style={role === 1 ? { borderBottom: 'none' } : {}}>{email}</td>
                <td style={role === 1 ? { width: '14%', borderBottom: 'none' } : { width: '14%'}}>
                    <div className='user-actions'>
                        <BsGear className='icon-button' style={{ color: 'green' }} color='green' onClick={() => onEdit(id)} />
                        {email !== 'taulean@pfbc.md' &&
                            <BsArchive className='icon-button' color='red' onClick={() => onDelete(id)} />
                        }
                    </div>
                </td>
            </tr>
            {role === 1 &&
                <SecondRow />
            }
        </>
    );
};

const SettingsPage = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { list } = useSelector(state => state.users);
    const { user } = useSelector(state => state.auth);
    const [isAdmin, setIsAdmin] = useState(1);
    const [modalData, setModalData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user) {
            const { accessToken } = user;
            const decoded = jwt_decode(accessToken);
            const role = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Администратор' ? 0 : 1;
            setIsAdmin(parseInt(role));
        }
    }, [user, setIsAdmin]);

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchProjects()).then(() => {
            dispatch(fetchUsers())
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        });
    }, [dispatch]);

    const onAddHandler = () => {
        setShowModal(true);
    };

    const onEditHandler = (id) => {
        const data = list.find(item => item.id === id);
        //console.log(data);
        setModalData({...data});
        setShowModal(true);
    };

    const onDeleteHandler = (id) => {
        if (isLoading === true) return;

        setIsLoading(true);
        //console.log('delete user', id);
        dispatch(deleteUser(id))
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    };

    const onCancelHandler = () => {
        setModalData(null);
        setShowModal(false);
    };

    const onSaveHandler = (data) => {
        if (isLoading === true) return;

        setIsLoading(true);
        if (data.id !== null) {
            dispatch(updateUser(data))
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        } else {
            dispatch(addUser(data))
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        };
        setShowModal(false);
        setModalData(null);
    };

    if (isAdmin === 1) return <Trans>Access denied</Trans>;

    return (
        <>
            <div className='settings-button-container'>
                {isLoading && <PulseLoader
                    style={{ marginRight: 12 }}
                    color="#D8AF62"
                    size={10}
                    margin={4}
                    speedMultiplier={1}
                />}
                <StyledButton name={t`Add user`} onClickHandler={onAddHandler} />
            </div>
            <div className="row">
                <table className="table">
                    <thead>
                        <tr>
                            <td>#</td>
                            <td><Trans>User</Trans></td>
                            <td style={{ width: '14%', textAlign: 'center' }}><Trans>Actions</Trans></td>
                        </tr>
                        <tr>
                            <td colSpan={3}><Trans>Projects</Trans></td>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(user => {
                            const { id } = user;
                            return (
                                <UserRow key={id} user={user} onEdit={onEditHandler} onDelete={onDeleteHandler} />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {showModal === true && <Modal modalData={modalData} onCancel={onCancelHandler} onSave={onSaveHandler} />}
        </>
    );
};

export default SettingsPage;