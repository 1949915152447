import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Trans, t } from "@lingui/macro";
import { useDispatch, useSelector } from 'react-redux';

import { fetchConsolidatedReport } from '../store/reportSlice';

import Container from '../components/Container/Container';
import PageHeader from '../components/PageHeader';

import Filter from '../components/Filter';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
);


const options = {
    plugins: {
        legend: {
            display: false
        }
    }
};

const PortfoliiPage = () => {

    const dispatch = useDispatch(); 
    const [currentFilter, setCurrentFilter] = useState(null);
    const initialDataState = {
        labels: [t`Jan`, t`Feb`, t`Mar`, t`Apr`, t`May`, t`Jun`, t`Jul`, t`Aug`, t`Sep`, t`Oct`, t`Nov`, t`Dec`],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    };
    const [isLoading, setIsLoading] = useState(false);
    const { consolidatedReportData } = useSelector(state => state.reports);

    //console.log('rep data', consolidatedReportData.length === 0 ? [{ ...initialDataState }.data] : consolidatedReportData?.map((item) => { return item.countInWork }));

    const currentLabels = consolidatedReportData.length <= 12 ?
        { ...initialDataState }.labels
        :
        consolidatedReportData.map((item) => { return item.key }); 

    //console.log(currentLabels);

    const dataCountCases = {
        labels: currentLabels,
        datasets: [
            {
                label: '',
                data: consolidatedReportData?.length === 0 ? [{ ...initialDataState }.data] : consolidatedReportData?.map((item) => { return item.countInWork }),
                backgroundColor: ['#D8AF62'],
            },
        ],
    };

    const dataAmountRecived = {
        labels: currentLabels,
        datasets: [
            {
                label: '',
                data: consolidatedReportData?.length === 0 ? [{ ...initialDataState }.data] : consolidatedReportData?.map((item) => { return item.debtAmount }),
                backgroundColor: ['#D8AF62'],
            },
        ],
    };

    const dataRateReturned = {
        labels: currentLabels,
        datasets: [
            {
                label: '',
                data: consolidatedReportData?.length === 0 ? [{ ...initialDataState }.data] : consolidatedReportData?.map((item) => { return item.percentReturn }),
                backgroundColor: ['#D8AF62'],
            },
        ],
    };

    useEffect(() => {
        if (currentFilter) {
            //currentFilter.months = [2, 3, 4]
            //currentFilter.yaer =
            setIsLoading(true);
            dispatch(fetchConsolidatedReport(currentFilter))
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        };
    }, [currentFilter, fetchConsolidatedReport]);

    return (
        <>
            <Filter
                onChangeFilter={(value) => setCurrentFilter(value)}
                isLoading={isLoading}
            />

            <div className="row">
                <div className="col s12">
                    <div className="block lime">
                        <div className="title sb bold"><Trans>Qty of cases received in work per month</Trans></div>
                        <Bar data={dataCountCases} options={options} />
                    </div>
                    <div className="block lime">
                        <div className="title sb bold"><Trans>Amount of debts received in work</Trans></div>
                        <Bar data={dataAmountRecived} options={options} />
                    </div>
                    <div className="block lime">
                        <div className="title sb bold"><Trans>Rate of return by portfolio of cases</Trans></div>
                        <Bar data={dataRateReturned} options={options} />
                    </div>
                </div>
            </div>

        </>
    );
};

export default PortfoliiPage;