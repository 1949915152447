import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
//import { i18n } from '@lingui/core';
//import { I18nProvider } from '@lingui/react';

import AppRoutes from './AppRoutes';

import { LoginPage } from './pages';

import Container from './components/Container';
import PageHeader from './components/PageHeader';

import './assets/css/style.css';

const App = () => {

    const dispatch = useDispatch();
    const { isAuth } = useSelector(state => state.auth);

    useEffect(() => {
        if (isAuth) {
            //console.log('isauth');
            //dispatch(fetchDictionaties());
        };
    }, [isAuth, dispatch]);

    return (
        <>
            {!isAuth ?
                <LoginPage />
                :
                <>
                    <PageHeader />
                    <Container>
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                        </Routes>
                    </Container>
                </>
            }
        </>
    );

};

export default App;