import React, { useState, useEffect } from 'react';
import { Trans, t } from "@lingui/macro";
import Select from 'react-select';
import { useSelector } from 'react-redux';
import PulseLoader from "react-spinners/PulseLoader";

import filter from '../../assets/img/filter.svg';

const Filter = ({ onChangeFilter = () => { }, isLoading = false }) => {

    //const { isLoading } = useSelector(state => state.reports);

    const [yearFilter, setYearFilter] = useState(state => {
        let initialState = [];
        const today = new Date();
        const currentYear = today.getFullYear();

        const startYear = 2017;
        for (let i = startYear; i <= currentYear; i++) {
            initialState.push({ year: i, current: currentYear === i ? true : false });
        };

        return initialState;
    });

    const allYears = yearFilter.map(x => ({ value: x.year, label: x.year }));

    const [monthFilter, setMonthFilter] = useState([0]);

    const [mQuery, setMQuery] = useState({
        matches: window.innerWidth > 780 ? true : false,
    });

    useEffect(() => {
        let mediaQuery = window.matchMedia("(min-width: 780px)");
        mediaQuery.addListener(setMQuery);
        // this is the cleanup function to remove the listener
        return () => mediaQuery.removeListener(setMQuery);
    }, []);

    const [showFilter, setShowFilter] = useState(false);
    useEffect(() => {
        //console.log('mobile', mQuery);
        if (mQuery?.matches === true && showFilter === true) {
            setShowFilter(false);
        }
    }, [mQuery, showFilter, setShowFilter]);
    
    const onChangeYearHandler = ({ value }) => {
        //console.log(value);
        setYearFilter(state => {
            const newState = state.map((item) => {
                return {
                    year: item.year, current: item.year === parseInt(value) ? true : false
                }
            });
            return newState;
        });
    };

    const allMonths = [
        {
            value: 0,
            label: t`All year`
        },
        {
            value: 1,
            label: t`January`
        },
        {
            value: 2,
            label: t`February`
        },
        {
            value: 3,
            label: t`March`
        },
        {
            value: 4,
            label: t`April`
        },
        {
            value: 5,
            label: t`May`
        },
        {
            value: 6,
            label: t`June`
        },
        {
            value: 7,
            label: t`July`
        },
        {
            value: 8,
            label: t`August`
        },
        {
            value: 9,
            label: t`September`
        },
        {
            value: 10,
            label: t`October`
        },
        {
            value: 11,
            label: t`November`
        },
        {
            value: 12,
            label: t`December`
        },
    ];

    const onChangeMonthHandler = (value) => {
        console.log(value.length > 1, value.length === 0 || value.includes(0));
        //setMonthFilter([value]);
        //if value.includes(2)
        setMonthFilter(state => {
            let newMonths = [];

            if (value.length > 1) {
                newMonths = value.filter(x => x.value !== 0).map(x => {
                    return x.value;
                });
            } else if (value.length === 0 || value.includes(0)) {
                newMonths = [0];
            } else {
                newMonths = value.map(x => {
                    return x.value;
                });
            };

            console.log(newMonths);
            return newMonths;
        });

        //console.log(value);
        //if (onChangeMonth)
        //    onChangeMonth(value);
    };

    useEffect(() => {
        onChangeFilter({
            year: yearFilter.find(item => item.current === true).year,
            months: monthFilter
        });
    }, [yearFilter, monthFilter]);

    const onTriggerFilterHandler = () => {
        if (mQuery.matches === false) {
            setShowFilter(state => !state);
        } else {
            setShowFilter(true);
        }
    };

    return (
        <div className="filter">
            <span id="filter" onClick={onTriggerFilterHandler}><img src={filter} alt="" /> <Trans>Filters</Trans></span>
            <div style={mQuery.matches === false ? (showFilter === true ? { display: 'block' } : { display: 'none' }) : { display: 'flex' }}>
                <Select
                    //defaultValue={}
                    //isMulti
                    name="years"
                    placeholder="Выберите год"
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    value={yearFilter.map(x => {
                        if (x.current)
                            return allYears.find(l => l.value == x.year)
                    })}
                    onChange={onChangeYearHandler}
                    options={allYears}
                    className="select-years-filter"
                    classNamePrefix="select-years"
                />
                <Select
                    //defaultValue={}
                    isMulti
                    name="months"
                    placeholder="Выберите месяцы"
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                    value={monthFilter.map(x => {
                        //if (x.current)
                            return allMonths.find(l => l.value == x)
                    })}
                    onChange={onChangeMonthHandler}
                    options={allMonths}
                    className="select-months-filter"
                    classNamePrefix="select-months"
                />
                {isLoading && <PulseLoader
                    style={{marginLeft: 12} }
                    color="#D8AF62"
                    size={10}
                    margin={4}
                    speedMultiplier={1}
                />}
            </div>
        </div>
    );
};

export default Filter;

                //<select className="select" value={yearFilter.find(item => item.current === true).year} onChange={(e) => onChangeYearHandler(e.target)}>
                //    {yearFilter.map((item) => {
                //        return <option key={item.year} value={item.year}>{item.year}</option>
                //    })}
                //</select>
                //<select className="select" value={monthFilter[0]} onChange={(e) => onChangeMonthHandler(e.target)}>
                //    <option value={0}><Trans>All year</Trans></option>
                //    <option value={1}><Trans>January</Trans></option>
                //    <option value={2}><Trans>February</Trans></option>
                //    <option value={3}><Trans>March</Trans></option>
                //    <option value={4}><Trans>April</Trans></option>
                //    <option value={5}><Trans>May</Trans></option>
                //    <option value={6}><Trans>June</Trans></option>
                //    <option value={7}><Trans>July</Trans></option>
                //    <option value={8}><Trans>August</Trans></option>
                //    <option value={9}><Trans>September</Trans></option>
                //    <option value={10}><Trans>October</Trans></option>
                //    <option value={11}><Trans>November</Trans></option>
                //    <option value={12}><Trans>December</Trans></option>
                //</select>