//import React from 'react';
//import { t } from "@lingui/macro";

export const dataForSummary = (allData, stage) => {
    let data = {
        casesReceived: 0,
        amountReceived: 0,

        termOfDelay: 0,
        paymentAmount: 0,

        casesFullyPaid: 0,
        averagePayment: 0,
    };

    switch (parseInt(stage)) {
        case 0://FOR ALL
            //let size = 0;
            Object.keys(allData).forEach((key) => {
                data.casesReceived += allData[key].casesReceived;
                data.amountReceived += allData[key].amountReceived;
                //Object.keys(allData[key].amountReceivedAdditional).forEach((index) => {
                //    data.amountReceived += allData[key].amountReceivedAdditional[index];
                //});

                data.termOfDelay += allData[key].termOfDelay;
                data.paymentAmount += allData[key].paymentAmount;

                data.casesFullyPaid += allData[key].casesFullyPaid;
                data.averagePayment += allData[key].averagePayment;

            });

            const size = Object.keys(allData).length;
            //среднее по средним знаениям
            data.termOfDelay = data.termOfDelay / size;
            data.averagePayment = data.averagePayment / size;

            break;
        case 1://FOR LEGAL
            data.casesReceived += allData.legal.casesReceived;
            data.amountReceived += allData.legal.amountReceived;
            //Object.keys(allData.legal.amountReceivedAdditional).forEach((key) => {
            //    data.amountReceived += allData.legal.amountReceivedAdditional[key];
            //});

            data.termOfDelay += allData.legal.termOfDelay;
            data.paymentAmount += allData.legal.paymentAmount;

            data.casesFullyPaid += allData.legal.casesFullyPaid;
            data.averagePayment += allData.legal.averagePayment;

            break;
        case 2://FOR SOFT
            data.casesReceived += allData.soft.casesReceived;
            data.amountReceived += allData.soft.amountReceived
            //Object.keys(allData.soft.amountReceivedAdditional).forEach((key) => {
            //    data.amountReceived += allData.soft.amountReceivedAdditional[key];
            //});

            data.termOfDelay += allData.soft.termOfDelay;
            data.paymentAmount += allData.soft.paymentAmount;

            data.casesFullyPaid += allData.soft.casesFullyPaid;
            data.averagePayment += allData.soft.averagePayment;

            break;
        case 3://FOR HARD
            data.casesReceived += allData.hard.casesReceived;
            data.amountReceived += allData.hard.amountReceived
            //Object.keys(allData.hard.amountReceivedAdditional).forEach((key) => {
            //    data.amountReceived += allData.hard.amountReceivedAdditional[key];
            //});

            data.termOfDelay += allData.hard.termOfDelay;
            data.paymentAmount += allData.hard.paymentAmount;

            data.casesFullyPaid += allData.hard.casesFullyPaid;
            data.averagePayment += allData.hard.averagePayment;

            break;
        case 4://SYSTEM
            data.casesReceived += allData.system.casesReceived;
            data.amountReceived += allData.system.amountReceived
            //Object.keys(allData.system.amountReceivedAdditional).forEach((key) => {
            //    data.amountReceived += allData.system.amountReceivedAdditional[key];
            //});

            data.termOfDelay += allData.system.termOfDelay;
            data.paymentAmount += allData.system.paymentAmount;

            data.casesFullyPaid += allData.system.casesFullyPaid;
            data.averagePayment += allData.system.averagePayment;

            break;
        case 9://FOR ONE OF ALL
            data.casesReceived += allData.casesReceived;
            data.amountReceived += allData.amountReceived
            //Object.keys(allData.amountReceivedAdditional).forEach((key) => {
            //    data.amountReceived += allData.amountReceivedAdditional[key];
            //});

            data.termOfDelay += allData.termOfDelay;
            data.paymentAmount += allData.paymentAmount;

            data.casesFullyPaid += allData.casesFullyPaid;
            data.averagePayment += allData.averagePayment;

            break;
    };

    return data;
};

export const dataForSummaryAdditional = (allData, stage) => {
    switch (parseInt(stage)) {
        case 0:
            let data = {
                systemAction: 0,
                information: 0,
                interactiveVoiceMessaging: 0,
                withoutContact: 0,
                documents: 0,
                succeeded: 0,
                postalNotification: 0
            };
            Object.keys(allData).forEach((keyStage) => {
                Object.keys(allData[keyStage].amountReceivedAdditional).forEach((key) => {
                    data[key] += allData[keyStage].amountReceivedAdditional[key];
                });
            });

            return data;
        case 1:
            return allData.legal.amountReceivedAdditional;
        case 2:
            return allData.soft.amountReceivedAdditional;
        case 3:
            return allData.hard.amountReceivedAdditional;
        case 4:
            return allData.system.amountReceivedAdditional;
        case 9:
            return allData.amountReceivedAdditional;
    }
};

export const calcTotal = (dataset) => {
    const total = dataset.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
    );
    return total;
};

export const stageColorClass = {
    1: 'darkturquoise',  //LEGAL
    2: 'blue',           //SOFT
    3: 'red',             //HARD
    4: 'gray',             //HARD
};

//export const stageNames = {
//    1: t`Legal`,
//    2: t`Soft`,
//    3: t`Hard` 
//};

// Format the price above to USD using the locale, style, and currency.
export const MDLCurrency = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'MDL',
});

export const QTYNumber = new Intl.NumberFormat('ru-RU', {
    style: 'decimal'
});