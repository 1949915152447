import { LoginPage, StatisticPage, PortfoliiPage, SettingsPage, StagePage } from './pages';

const AppRoutes = [
    {
        index: true,
        element: <StatisticPage />
    },
    {
        path: '/login',
        element: <LoginPage />
    },
    {
        path: '/statistic',
        element: <StatisticPage />
    },

    {
        path: '/portfolii',
        element: <PortfoliiPage />
    },
    {
        path: '/portfolii-hard',
        element: <StagePage currenStage='3' />
    },
    {
        path: '/portfolii-soft',
        element: <StagePage currenStage='1' />
    },
    {
        path: '/portfolii-legal',
        element: <StagePage currenStage='2' />
    },
    {
        path: '/portfolii-system',
        element: <StagePage currenStage='4' />
    },
    {
        path: '/settings',
        element: <SettingsPage />
    },
];

export default AppRoutes;
