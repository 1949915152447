import React, { } from 'react';
import './StyledButton.css';

const StyledButton = ({ children, className = null, onClickHandler = () => { }, name }) => {
    return (
        <div
            onClick={onClickHandler}
            className={'styled-button ' + (className ? className : "")}
        >
            {name ? name : children}
        </div>
    );
};

export default StyledButton;
