import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import userSlice from './userSlice';
import projectSlice from './projectSlice';
import reportSlice from './reportSlice';

export default configureStore({
    reducer: {
        projects: projectSlice,
        auth: authSlice,
        users: userSlice,
        reports: reportSlice
    }
});
