import React, { useState, useEffect }from 'react';

import './CustomSelect.modules.css';

const CustomSelect = ({ optionsList = [], value = {id: 'en', name: 'EN'}, onSelect = () => {}}) => {
    const [showOptionList, setShowOptionList] = useState(false);

    const setShowOptionListHandler = () => {
        setShowOptionList(state => !state);
    };

    const optionClickHandler = ({ target }) => {
        const selectedId = target.getAttribute('value');
        const selectedItem = optionsList.find(item => item.id === selectedId);
        //console.log(target.getAttribute('value'));
        //console.log(selectedItem);
        onSelect({ ...selectedItem });
        setShowOptionList(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", clickOutsideHandler);

        return () => {
            document.removeEventListener("mousedown", clickOutsideHandler);
        };
    }, []);

    const clickOutsideHandler = (e) => {
        if (
            !e.target.classList.contains("custom-select-option") &&
            !e.target.classList.contains("selected-text")
        ) {
            setShowOptionList(false);
        };
    };

    return (
        <div className="custom-select-container">
            <div
                className={showOptionList ? "selected-text active" : "selected-text"}
                onClick={setShowOptionListHandler}
            >
                {value.name}
            </div>
            {showOptionList && (
                <ul className="select-options">
                    {optionsList.map(option => {
                        if (value.id !== option.id) {
                            return (
                                <li
                                    className="custom-select-option"
                                    data-name={option.name}
                                    key={option.id}
                                    value={option.id}
                                    onClick={optionClickHandler}
                                >
                                    {option.name}
                                </li>
                            );
                        };
                    })}
                </ul>
            )}
        </div>

    );
};

export default CustomSelect;


//<div id='language-selector' class='select'>
//    RU
//        </div>