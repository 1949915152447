import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { checkAuth } from './authSlice';

export const fetchYearsFilter = createAsyncThunk(
    'reports/fetchYearsFilter',
    async function (_, { rejectWithValue, getState, dispatch }) {

        try {
            const { user } = getState().auth;

            await dispatch(checkAuth(user));

            const response = await fetch('/api/report/years', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Server Error!');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);

export const fetchFullReport = createAsyncThunk(
    'reports/fetchFullReport',
    async function (filter, { rejectWithValue, getState, dispatch }) {

        try {
            const { user } = getState().auth;

            await dispatch(checkAuth(user));

            const response = await fetch('/api/report/full', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
                body: JSON.stringify({ ...filter })
            });

            if (!response.ok) {
                throw new Error('Server Error!');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);

export const fetchStageReport = createAsyncThunk(
    'reports/fetchStageReport',
    async function (filter, { rejectWithValue, getState, dispatch }) {
        try {
            const { user } = getState().auth;

            await dispatch(checkAuth(user));

            const response = await fetch(`/api/report/stage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
                body: JSON.stringify({ ...filter })
            });

            if (!response.ok) {
                throw new Error('Server Error!');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);


export const fetchYearReport = createAsyncThunk(
    'reports/fetchYearReport',
    async function (filter, { rejectWithValue, getState, dispatch }) {
        try {
            const { user } = getState().auth;

            await dispatch(checkAuth(user));

            const response = await fetch('/api/report/year', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
                body: JSON.stringify({ ...filter })
            });

            if (!response.ok) {
                throw new Error('Server Error!');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);

export const fetchConsolidatedReport = createAsyncThunk(
    'reports/fetchConsolidatedReport',
    async function (filter, { rejectWithValue, getState, dispatch }) {
        try {
            const { user } = getState().auth;

            await dispatch(checkAuth(user));

            const response = await fetch('/api/report/consolidated', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
                body: JSON.stringify({ ...filter })
            });

            if (!response.ok) {
                throw new Error('Server Error!');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);


const amountReceivedAdditionalInitial = {
    systemAction: 0,
    information: 0,
    interactiveVoiceMessaging: 0,
    withoutContact: 0,
    documents: 0,
    succeeded: 0,
    postalNotification: 0
};

const stageInitial = {
    casesReceived: 0,
    amountReceived: 0,
    amountReceivedAdditional: amountReceivedAdditionalInitial,
    termOfDelay: 0,
    paymentAmount: 0,
    casesFullyPaid: 0,
    averagePayment: 0
};

export const reportSlice = createSlice({
    name: 'reports',
    initialState: {
        stagesReportData: {
            hard: {
                ...stageInitial
            },
            soft: {
                ...stageInitial
            },
            legal: {
                ...stageInitial
            },
            system: {
                ...stageInitial
            }
        },
        stageReportData: { ...stageInitial },
        yearReportData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        consolidatedReportData: [],
        yearsFilterData: [],
        isLoading: false,
        error: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFullReport.fulfilled, (state, action) => {
                state.stagesReportData = action.payload;
            })
            .addCase(fetchStageReport.fulfilled, (state, action) => {
                state.stageReportData = action.payload;
            })
            .addCase(fetchYearReport.fulfilled, (state, action) => {
                state.yearReportData = action.payload;
            })
            .addCase(fetchConsolidatedReport.fulfilled, (state, action) => {
                state.consolidatedReportData = action.payload;
            })
            .addCase(fetchYearsFilter.fulfilled, (state, action) => {
                state.yearsFilterData = action.payload;
            })
            .addMatcher(isError, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            })
            .addMatcher(isPending, (state) => {
                state.error = null;
                state.isLoading = true;
            })
            .addMatcher(isFulfilled, (state) => {
                state.isLoading = false;
            });
    }
})

// Action creators are generated for each case reducer function
export const { } = reportSlice.actions

export default reportSlice.reducer

function isError(action) {
    return action.type.endsWith('rejected') && action.type.startsWith('reports');
};

function isPending(action) {
    return action.type.endsWith('pending') && action.type.startsWith('reports');
};

function isFulfilled(action) {
    return action.type.endsWith('fulfilled') && action.type.startsWith('reports');
};
