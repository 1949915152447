import React, { useContext, createContext } from 'react';

const currentLanguage = localStorage.getItem("pfb_language");

const InitialState = {
    locale: currentLanguage ? currentLanguage : 'en',
    setLocale: () => undefined,
};

export const I18nContext = createContext(InitialState);

export const useI18nContext = () => {
    const context = useContext(I18nContext);

    if (context === null) {
        throw new Error('"useI18nContext" should be used inside a "I18nContextProvider"');
    }

    return context;
};

