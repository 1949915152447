import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    CategoryScale,
    LinearScale,
    BarElement,
    Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Trans, t } from "@lingui/macro";
import { useDispatch, useSelector } from 'react-redux';

import { fetchStageReport } from '../store/reportSlice';

import { dataForSummary, dataForSummaryAdditional, stageColorClass, QTYNumber, calcTotal } from '../additional';

import Filter from '../components/Filter';
import SummaryData from '../components/SummaryData';
import LegendRow from '../components/LegendRow';

//import download from "../assets/img/download.svg";

//import dummyData from '../dummyData';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
);

const StagePage = ({ currenStage }) => {
    const [stage, setStage] = useState(currenStage);
    //const stage = currenStage;
    const dispatch = useDispatch();
    const { stageReportData } = useSelector(state => state.reports);
    const [isLoading, setIsLoading] = useState(false);
    //console.log(stageReportData);
    const [currentFilter, setCurrentFilter] = useState(null);

    useEffect(() => {
        //console.log('try fetch stage');
        if (currentFilter && stage) {
            //console.log('fwtch stage');
            setIsLoading(true);
            dispatch(fetchStageReport({ stage: parseInt(stage), ...currentFilter }))
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        };
    }, [currentFilter, stage, dispatch]);

    useEffect(() => {
        setStage(currenStage);
    }, [currenStage]);

    const dataStageAdittional = dataForSummaryAdditional(stageReportData, 9); //Stage 9 - используется для страниц не разбитых по этапам
    const totalData = dataForSummary(stageReportData, 9);

    const data = {
        labels: [t`System Action`, t`Information`, t`Interactive Voice Messaging`, t`Without contact`, t`Documents`, t`Succeeded`, t`Postal Notification`],
        datasets: [
            {
                label: '',
                data: [dataStageAdittional.systemAction, dataStageAdittional.information, dataStageAdittional.interactiveVoiceMessaging, dataStageAdittional.withoutContact, dataStageAdittional.documents, dataStageAdittional.succeeded, dataStageAdittional.postalNotification],
                backgroundColor: [
                    '#35A8E0',
                    '#D9AF62',
                    '#F05161',
                    '#979797',
                    '#8268BA',
                    '#FF69C3',
                    '#93C01F',
                ],
                borderColor: [
                    '#FFFFFF',
                ],
                borderWidth: 1,
                cutout: "65%",
            },
        ],
    };
    return (
        <>
            <Filter
                onChangeFilter={(value) => setCurrentFilter(value)}
                isLoading={isLoading}
            />
            <SummaryData
                data={totalData}
                stage={stage}
            />

            <div className="row">
                <div className="col s12">
                    <div className={"block " + stageColorClass[stage]}>
                        <div className="title">
                            <Trans>Qty of actions</Trans>
                        </div>
                        <div className="block_row">
                            <div className='graf-container'>
                                <div className='graf-text-container'>
                                    <div className='graf-header'><Trans>TOTAL</Trans></div>
                                    <div className='graf-footer'>{QTYNumber.format(calcTotal(data.datasets[0].data))}</div>
                                </div>
                                <Doughnut
                                    data={data}
                                    width={272}
                                    height={272}
                                    options={{
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                        },
                                        maintainAspectRatio: true,
                                        responsive: false
                                    }}
                                />
                            </div>
                            <div className="block_row_col">
                                {data !== null && data.labels.map((legendItem, index) => {
                                    const rowData = {
                                        color: data.datasets[0].backgroundColor[index],
                                        description: legendItem,
                                        total: data.datasets[0].data[index]
                                    };
                                    return (
                                        <LegendRow key={index} legendRowData={rowData} showWraper={data.labels.length - 1 !== index} />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StagePage;

//<div className="title right"><a href="/"><Trans>Download</Trans><img src={download} /></a></div>
