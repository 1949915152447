import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { checkAuth } from './authSlice';

export const fetchProjects = createAsyncThunk(
    'projects/fetchProjects',
    async function (_, { rejectWithValue, getState, dispatch }) {
        try {
            const { user } = getState().auth;

            await dispatch(checkAuth(user));

            const response = await fetch('/api/project', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Server Error!');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);

export const addProject = createAsyncThunk(
    'projects/addProject',
    async function (newProject, { rejectWithValue, getState, dispatch }) {
        try {
            const { user } = getState().auth;
            //console.log(user.accessToken)
            await dispatch(checkAuth(user));
            //console.log(getState().auth);
            //console.log(getState().auth);

            const response = await fetch('/api/project', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
                body: JSON.stringify({ ...newProject })
            });

            if (!response.ok) {
                throw new Error('Server Error!');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);

export const updateProject = createAsyncThunk(
    'projects/updateProject',
    async function (udatedProject, { rejectWithValue, getState, dispatch }) {
        try {
            const { user } = getState().auth;
            const { id } = udatedProject;
            //console.log(user.accessToken)
            await dispatch(checkAuth(user));
            //console.log(getState().auth);
            //console.log(getState().auth);

            const response = await fetch(`/api/project/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
                body: JSON.stringify({ ...udatedProject })
            });

            if (!response.ok) {
                throw new Error('Server Error!');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.log("ERROR", error);
            return rejectWithValue(error.message);
        }
    }
);

export const deleteProject = createAsyncThunk(
    'projects/deleteProject',
    async function (id, { rejectWithValue, getState, dispatch }) {
        const { user } = getState().auth;
        await dispatch(checkAuth(user));
        //console.log('current id', id)

        const response = await fetch(`/api/project/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${user.accessToken}`
            }
        });

        if (!response.ok) {
            return rejectWithValue('Не удалось удалить проект');
        }

        return id;
    }
);

export const projectSlice = createSlice({
    name: 'projects',
    initialState: {
        list: [],
        isLoading: false,
        error: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.fulfilled, (state, action) => {
                //console.log(action.payload);
                state.list = action.payload;
                //state.projectList = action.payload.projects;
            })
            .addCase(addProject.fulfilled, (state, action) => {
                const userList = state.list;
                userList.push(action.payload);
                userList.sort((a, b) => a.id - b.id);
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                const editedProject = state.list.find(project => project.id === action.payload.id);
                if (editedProject) {
                    state.list = state.list.map(project => {
                        if (project.id === action.payload.id)
                            return action.payload;
                        else
                            return project;
                    });
                };
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.list = state.list.filter(project => project.id !== action.payload);
            })
            .addMatcher(isError, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            })
            .addMatcher(isPending, (state) => {
                state.error = null;
                state.isLoading = true;
            })
            .addMatcher(isFulfilled, (state) => {
                state.isLoading = false;
            });
    }
})

// Action creators are generated for each case reducer function
export const { } = projectSlice.actions

export default projectSlice.reducer

function isError(action) {
    return action.type.endsWith('rejected') && action.type.startsWith('projects');
};

function isPending(action) {
    return action.type.endsWith('pending') && action.type.startsWith('projects');
};

function isFulfilled(action) {
    return action.type.endsWith('fulfilled') && action.type.startsWith('projects');
};
