import React, { useState, useEffect } from 'react';
//import Select from 'react-select';

import { useI18nContext } from "../../providers";

import CustomSelect from '../UI/CustomSelect/index';

//const LanguageSelector = () => {
//    const { locale, setLocale } = useI18nContext();

//    return (
//        <select id='language-selector' className="select" value={locale} onChange={(e) => setLocale(e.target.value)}>
//            <option value='en'>EN</option>
//            <option value='ro'>RO</option>
//            <option value='ru'>RU</option>
//        </select>
//    );
//};

const LanguageSelector = () => {
    const { locale, setLocale } = useI18nContext();

    const languageList = [{ id: 'en', name: 'EN' }, { id: 'ro', name: 'RO' }, { id: 'ru', name: 'RU' }];

    return (
        <CustomSelect
            optionsList={languageList}
            value={languageList.find(item => item.id === locale)}
            onSelect={(item) => setLocale(item.id)}
        />
    );
};

export default LanguageSelector;
