import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import PulseLoader from "react-spinners/PulseLoader";

import { useI18nContext } from "../providers";

import { login, checkAuth, setIsLoadingFalse, setRememberMe, restorePasswordRequest } from '../store/authSlice';

import { ReactComponent as Logo } from '../assets/img/logo.svg';
import headerEN from "../assets/img/headerEN.png";
import headerRO from "../assets/img/headerRO.png";
import headerRU from "../assets/img/headerRU.png";
import LanguageSelector from '../components/LanguageSelector/index';

const HeaderImg = () => {
    const { locale } = useI18nContext();

    if (locale === 'en') return <img src={headerEN} alt="" />;
    if (locale === 'ro') return <img src={headerRO} alt="" />;
    if (locale === 'ru') return <img src={headerRU} alt="" />;
};

const LoginPage = () => {
    const { locale } = useI18nContext();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { isAuth, error } = useSelector(state => state.auth);
    const { pathname } = useLocation();
    const [forgotPassword, setForgotPassword] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState(null);

    const onAuthHandler = (e) => {
        e.preventDefault();
        //console.log({ email, password });
        setIsLoading(true);
        dispatch(login({ email, password }))
            .then(() => setIsLoading(false));
    };

    console.log(errorText);

    useEffect(() => {
        if (error !== null) {
            //if (error === '')

            switch (error) {
                case 'Пользователь не найден.':
                    setErrorText(t`User not found`);
                    break;
                case 'Неверный пароль.':
                    setErrorText(t`Incorrect password.`);
                    break;
                default:
                    setErrorText(t`Server error.`);
                    break;
            }

            //setErrorText(error);
        } else {
            setErrorText(null);
        };
    }, [error]);

    console.log(error);
    //const onChangeEmailHandler = (target) => {
    //    console.log(target.value);
    //};

    //const onRememberMeChangeHandler = () => {
    //    setRememberMe(state => !state);
    //};

    const onRequestNewPasswordHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        dispatch(restorePasswordRequest(email)).then(() => {
            setRequestSent(true);
            setIsLoading(false);
        }).catch(() => {
            setRequestSent(false);
            setIsLoading(false);
        });
    };

    const onBackToLoginHandler = () => {
        setRequestSent(false);
        setForgotPassword(!forgotPassword);
    };

    useEffect(() => {
        if (sessionStorage.getItem("pfb_auth_data")) {
            dispatch(checkAuth(JSON.parse(sessionStorage.getItem("pfb_auth_data"))));
        } else if (localStorage.getItem("pfb_auth_data")) {
            dispatch(checkAuth(JSON.parse(localStorage.getItem("pfb_auth_data"))));
        } else {
            dispatch(setIsLoadingFalse());
        };
    }, [dispatch]);

    if (isAuth === true && pathname === "/login") {
        return (
            <Navigate to="/" replace={true} />
        )
    }

    return (
        <div className='loginWrapper'>
            <header style={{ position: 'relative' }}>
                <div className="header-left" >
                    <Logo />
                </div>
                <HeaderImg />
                <div className={"header-right-" + locale} />
                <div style={{ position: 'absolute', top: 0, right: 16 }}>
                    <LanguageSelector />
                </div>
            </header>
            <main>
                <div className="container">
                    <h1><Trans>Personal cabinet</Trans></h1>
                    <div className='fl'>{forgotPassword ? <Trans>Restore password</Trans> : <Trans>Login</Trans>}</div>
                    <form method="post" action="">
                        <span className="login"><input type="text" disabled={requestSent} name="login" placeholder={t`Username`} value={email} onChange={(e) => setEmail(e.target.value)} /></span>
                        {forgotPassword ?
                            <>
                                {requestSent ?
                                    <>
                                        {errorText ?
                                            <div className='error-text'>{errorText}</div>
                                            :
                                            <div className='forgot-description'><Trans>A password reset request has been sent to the administrator, please wait for an email.</Trans></div>
                                        }
                                    </>
                                :
                                <>
                                    <div className='forgot-description'><Trans>Once you've submitted the request, you should receive an email from the administrator containing a new password. If you don't see the email in your inbox, please check your spam folder as it may have been filtered there.</Trans></div>
                                        {errorText && <div className='error-text'>{errorText}</div>}
                                        {isLoading ?
                                            <div style={{ textAlign: 'center' }} >
                                                <PulseLoader
                                                    style={{}}
                                                    color="#D8AF62"
                                                    size={10}
                                                    margin={4}
                                                    speedMultiplier={1}
                                                />
                                            </div>
                                            :
                                            <button type="submit" onClick={onRequestNewPasswordHandler}><Trans>Send request</Trans></button>
                                        }
                                </>
                                }
                                <a className='back-link' href="#" onClick={onBackToLoginHandler}><Trans>Go back to login</Trans></a>
                            </>
                            :
                            <>
                                <span className="password"><input type="password" name="password" placeholder={t`Password`} value={password} onChange={(e) => setPassword(e.target.value)} /></span>
                                <div>
                                    <input type="checkbox" name="remember" id="remembre" onChange={() => dispatch(setRememberMe())} /><label htmlFor="remembre"><Trans>Remember Me</Trans></label>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setForgotPassword(!forgotPassword)
                                    }
                                    }><Trans>Forgot Password?</Trans></a>
                                </div>
                                {isLoading ?
                                    <div style={{ textAlign: 'center' }} >
                                        <PulseLoader
                                            style={{  }}
                                            color="#D8AF62"
                                            size={10}
                                            margin={4}
                                            speedMultiplier={1}
                                        />
                                    </div>
                                    :
                                    <>
                                        {errorText && <div className='error-text'>{errorText}</div>}
                                        <button type="submit" onClick={(e) => onAuthHandler(e)}><Trans>Login</Trans></button>
                                    </>
                                }
                            </>
                        }
                    </form>
                </div>
            </main>
        </div>
    );
};

export default LoginPage;

//<li className="active"><a href="">Login</a></li>
//<li><a href="">Register</a></li>


            //<div className='login-page-header'>
            //                    <div className='logo'><Logo size={170} /></div>
            //                    <img className='header-img' src={header} />
            //                </div>
            //                <div className='header'>
            //                    <hr className='line' />
            //                    <div className='header-text'>PERSONAL CABINET</div>
            //                    <hr className='line' />
            //                </div>
            //                <div className='login-form'>
            //                    <div className='form-text'>Login</div>
            //                    <div className="wrapper">

            //                        <div className="icon"><LoginIcon /></div>
            //                        <input className="input" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}></input>
            //                    </div>
            //                    <div className="wrapper">
            //                        <div className="icon"><PasswordIcon /></div>
            //                        <input className="input" type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}></input>
            //                    </div>
            //                    <div className='additional-buttons'>

            //                    </div>
            //                    <StyledButton name="Login" onClickHandler={onAuthHandler} />
            //                </div>