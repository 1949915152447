import React from 'react';

import NavMenu from '../NavMenu/';

const Container = ({ children }) => {
    return (
        <main>
            <div className="container">
                <div className="row">
                    <div className="left">
                        <NavMenu />
                    </div>
                    <div className="content">
                        {children}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Container;