import React from 'react';
import { Trans, t } from "@lingui/macro";

import { stageColorClass, MDLCurrency, QTYNumber } from '../../additional';

const SummaryData = ({ data = null, stage = 0 }) => {

    let summaryData = {
        casesReceived: 0,
        amountReceived: 0,

        termOfDelay: 0,
        paymentAmount: 0,

        casesFullyPaid: 0,
        averagePayment: 0,
    };

    const stageNames = {
        1: t`Soft`,
        2: t`Legal`,
        3: t`Hard`,
        4: t`System`
    };

    if (data !== null) {
        summaryData = { ...data };
    };

    //console.log('stage', stageNames);
    return (
        <>
            {stage != 0 && 
                <div className="row">
                    <div className="col s12">
                    <div className={"name " + stageColorClass[stage]}>{stageNames[stage]}<span> </span></div>
                    </div>
                </div>
            }
            <div className="row">
                <div className="col s4 block lime">
                    <Trans>Cases received (qty)</Trans>
                    <div className="count">
                        {QTYNumber.format(summaryData.casesReceived)}
                    </div>
                </div>
                <div className="col s4 block red">
                    <Trans>Amount received</Trans>
                    <div className="count">
                        {MDLCurrency.format(summaryData.amountReceived)}
                </div>
                </div>
                <div className="col s4 block blue">
                    <Trans>Avarage debt</Trans>
                    <div className="count">
                        {MDLCurrency.format(summaryData.casesReceived === 0 ? 0 : (summaryData.amountReceived / summaryData.casesReceived).toFixed())}
                </div>
                </div>

                <div className="col s4 block purple">
                    <Trans>Term of delay (days)</Trans>
                    <div className="count">
                        {QTYNumber.format(summaryData.termOfDelay.toFixed())}
                    </div>
                </div>
                <div className="col s4 block yellow">
                    <Trans>Payment amount</Trans>
                    <div className="count">
                        {MDLCurrency.format(summaryData.paymentAmount)}
                </div>
                </div>
                <div className="col s4 block green">
                    <Trans>Efficiency</Trans>
                    <div className="count">
                        {summaryData.casesReceived === 0 ? 0 : (summaryData.paymentAmount / summaryData.amountReceived * 100).toFixed(2)}%
                </div>
                </div>

                <div className="col s4 block pink">
                    <Trans>Cases fully paid (qty)</Trans>
                    <div className="count">
                        {QTYNumber.format(summaryData.casesFullyPaid)}
                    </div>
                </div>
                <div className="col s4 block orange">
                    <Trans>Average payment</Trans>
                    <div className="count">
                        {MDLCurrency.format(summaryData.averagePayment.toFixed())}
                    </div>
                </div>
                <div className="col s4 block gray">
                    <Trans>Closed cases</Trans>
                    <div className="count">
                        {summaryData.casesReceived === 0 ? 0 : (summaryData.casesFullyPaid / summaryData.casesReceived * 100).toFixed(2)}%
                </div>
                </div>
            </div>
        </>
    );
};

export default SummaryData;

//<div className={"col s4 block " + (stage != 0 ? stageColorClass[stage] : 'lime')}>
