import React from 'react';
import { MDLCurrency, QTYNumber } from '../../additional';

const LegendRow = ({ legendRowData, currency, showWraper = false }) => {
    const { color, description, total } = legendRowData;

    return (
        <div className="info">
            <div className="info_name"><span style={{ backgroundColor: color }}></span>{description}</div>
            <div className="info_count">{currency ? MDLCurrency.format(total) : QTYNumber.format(total)}</div>
        </div>
    );
};

export default LegendRow;