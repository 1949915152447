import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { Trans, t } from "@lingui/macro";

import './NavMenu.css';
import arrow from '../../assets/img/arrow.svg';

//import { logout } from '../../store/authSlice';

const NavMenu = ({ onClickMenu = null}) => {
    const { pathname } = useLocation();

    const [selectedSection, setSelectedSection] = useState(pathname);
    //const [inProp, setInProp] = useState(false);
    const nodeRef = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [selectedSubSection, setSelectedSubSection] = useState(null);//submenu

    const { isAuth, user } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [isAdmin, setIsAdmin] = useState(null);//user && jwt_decode(user.accessToken).role;

    //console.log(isAdmin, user ? jwt_decode(user.accessToken).role : null, user);

    useEffect(() => {
        if (user) {
            const { accessToken } = user;
            //console.log('TT', accessToken)

            const decoded = jwt_decode(accessToken);
            const role = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Администратор' ? 0 : 1;
            setIsAdmin(parseInt(role));
        }
    }, [user]);

    useEffect(() => {
        //console.log(pathname);
        if (pathname && !pathname.startsWith("/portfolii"))
            setShowSubMenu(false);
        else if (pathname && pathname.startsWith("/portfolii"))
            setShowSubMenu(true);
            

        setSelectedSection(pathname);
    }, [pathname]);


    //const logoutHandler = () => {
    //    dispatch(logout(user));
    //};
    //<li><Link to='/' onClick={logoutHandler}>Logout</Link></li>

    const noUpdateHandle = (e) => {
        e.preventDefault();
    }

    //console.log(showSubMenu);
    return (
        <menu onClick={() => onClickMenu ? onClickMenu() : {}}>
            <li className={(selectedSection === "/" || selectedSection === "/statistic") ? "active" : ""}><Link to='/statistic'><Trans>Dashboard</Trans></Link></li>
            <li className={"dropdown " + (selectedSection === "/portfolii" ? "active" : "")}><Link to='/portfolii'><Trans>Stage-by-stage analysis</Trans><img className={(showSubMenu === false ? '' : 'rotate')} onClick={() => { console.log(showSubMenu); setShowSubMenu(state => !state) }} src={arrow} alt="" /></Link>
                <ul className={(showSubMenu === true ? 'visible' : '')}>
                    <li className={selectedSection === "/portfolii-soft" ? "active" : ""} style={selectedSection === "/portfolii-soft" ? {} : { background: '#2D2E2E' }}><Link to='/portfolii-soft'><Trans>Soft</Trans></Link></li>
                    <li className={selectedSection === "/portfolii-legal" ? "active" : ""} style={selectedSection === "/portfolii-legal" ? {} : { background: '#2D2E2E' }}><Link to='/portfolii-legal'><Trans>Legal</Trans></Link></li>
                    <li className={selectedSection === "/portfolii-hard" ? "active" : ""} style={selectedSection === "/portfolii-hard" ? {} : { background: '#2D2E2E' }}><Link to='/portfolii-hard'><Trans>Hard</Trans></Link></li>
                    <li className={selectedSection === "/portfolii-system" ? "active" : ""} style={selectedSection === "/portfolii-system" ? {} : { background: '#2D2E2E' }}><Link to='/portfolii-system'><Trans>System</Trans></Link></li>
                </ul>
            </li>
            {isAdmin === 0 &&
                <li className={selectedSection === "/settings" ? "active" : ""}><Link to='/settings'><Trans>Settings</Trans></Link></li>
            }

        </menu>
    );
};

export default NavMenu;

//style = { showSubMenu === true ? { display: 'block' } : { display: 'none' }}

//<CSSTransition
//    in={showSubMenu}
//    nodeRef={nodeRef}
//    timeout={300}
//    classNames="my-node"
//>
//    <div ref={nodeRef}>
//<li className={selectedSection === "/incarca" ? "active" : ""}><Link to='/'>Incarca Dosar</Link></li>


//</CSSTransition >

//(selectedSection.startsWith("/portfolii")