import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Trans, t } from "@lingui/macro";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { fetchFullReport, fetchYearReport } from '../store/reportSlice';

import { dataForSummary, dataForSummaryAdditional, MDLCurrency, QTYNumber, calcTotal } from '../additional';

import Container from '../components/Container';
import PageHeader from '../components/PageHeader';
import SummaryData from '../components/SummaryData';
import LegendRow from '../components/LegendRow';

import filter from '../assets/img/filter.svg';
import Filter from '../components/Filter/index';

//Временные данные
//import dummyData from '../dummyData';


ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
);

const gColors = {
    'System Action': '#35A8E0'
};

const options = {
    plugins: {
        legend: {
            display: false,
            position: 'bottom'
        },
        tooltip: {
            //backgroundColor: 'red',
            //titleColor: ['red']
        },

    },
    maintainAspectRatio: true,
    responsive: false,
}

const optionsBar = {
    plugins: {
        legend: {
            display: false
        }
    },
    maintainAspectRatio: true
};

const StatisticPage = () => {
    const dispatch = useDispatch();
    const [selectedStage, setSelectedStage] = useState(0);
    const { stagesReportData, yearReportData } = useSelector(state => state.reports);
    const [isLoading, setIsLoading] = useState(false);

    const [currentFilter, setCurrentFilter] = useState(null);

    useEffect(() => {
        //console.log('filter changed', currentFilter);
        if (currentFilter) {
            setIsLoading(true);
            dispatch(fetchFullReport(currentFilter))
                .then(() => {
                    dispatch(fetchYearReport(currentFilter))
                        .then(() => setIsLoading(false))
                        .catch(() => setIsLoading(false));
            });
        };
    }, [currentFilter, fetchFullReport, fetchYearReport]);

    //if (!stagesReportData) return <>Загрузка данных</>;

    const allStages = [
        {
            value: 0,
            label: t`All`
        },
        {
            value: 2,
            label: t`Soft`
        },
        {
            value: 1,
            label: t`Legal`
        },
        {
            value: 3,
            label: t`Hard`
        },
        {
            value: 4,
            label: t`System`
        }
    ];

    const dataStageAdittional = dataForSummaryAdditional(stagesReportData, selectedStage);

    const dataEtap = {
        labels: [t`System Action`, t`Information`, t`Interactive Voice Messaging`, t`Without contact`, t`Documents`, t`Succeeded`, t`Postal Notification`],
        datasets: [
            {
                label: '',
                data: [dataStageAdittional.systemAction, dataStageAdittional.information, dataStageAdittional.interactiveVoiceMessaging, dataStageAdittional.withoutContact, dataStageAdittional.documents, dataStageAdittional.succeeded, dataStageAdittional.postalNotification],
                backgroundColor: [
                    '#35A8E0',
                    '#D9AF62',
                    '#F05161',
                    '#979797',
                    '#8268BA',
                    '#FF69C3',
                    '#93C01F',
                ],
                borderColor: [
                    '#FFFFFF',
                ],
                borderWidth: 1,
                cutout: "65%",
            },
        ],
    };


    const dataBar = {
        labels: [t`Jan`, t`Feb`, t`Mar`, t`Apr`, t`May`, t`Jun`, t`Jul`, t`Aug`, t`Sep`, t`Oct`, t`Nov`, t`Dec`],
        datasets: [
            {
                label: '',
                data: yearReportData,
                backgroundColor: '#D8AF62',
            },
        ],
    };

    const data = {
        labels: [t`Hard`, t`Soft`, t`Legal`, t`System`],
        datasets: [
            {
                label: '',
                data: [stagesReportData.hard.casesReceived, stagesReportData.soft.casesReceived, stagesReportData.legal.casesReceived, stagesReportData.system.casesReceived],
                backgroundColor: [
                    '#F05D6C',
                    '#35A8E0',
                    '#45D1BA',
                    '#354245',
                ],
                borderColor: [
                    '#FFFFFF',
                ],
                borderWidth: 1,
                cutout: "65%",
            },
        ],
    };

    //dataForSummaryAdditional(dummyData, 0);
    //console.log('dataForSummaryAdditional', dataForSummaryAdditional(dummyData, 3));

    return (
        <>
            <Filter
                onChangeFilter={(value) => setCurrentFilter(value)}
                isLoading={isLoading}
            />
            <SummaryData
                data={dataForSummary(stagesReportData, 0)}
                stage={0}
            />
            <div className="row">
                <div className="col s6">
                    <div className="block lime" >
                        <div className="title">
                            <Trans>Number of cases per phase</Trans>
                        </div>
                        <div className='graf-container'>
                            <div className='graf-text-container'>
                                <div className='graf-header'><Trans>TOTAL</Trans></div>
                                <div className='graf-footer'>{QTYNumber.format(calcTotal(data.datasets[0].data))}</div>
                            </div>
                            <Doughnut
                                data={data}
                                width={272}
                                height={272}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                    },
                                    maintainAspectRatio: true,
                                    responsive: false
                                }}
                            />
                        </div>
                        {data !== null && data.labels.map((legendItem, index) => {
                            const rowData = {
                                color: data.datasets[0].backgroundColor[index],
                                description: legendItem,
                                total: data.datasets[0].data[index]
                            };
                            return (
                                <LegendRow key={index} legendRowData={rowData} showWraper={data.labels.length - 1 !== index} />
                            );
                        })}
                    </div>
                    <div className="block lime">
                        <div className="title sb">
                            <Trans>Qty of cases received in work per month</Trans>
                        </div>
                        <Bar data={dataBar} options={optionsBar} />
                    </div>
                </div>
                <div className="col s6">
                    <div className="block red h100">
                        <div className="title sb">
                            <Trans>Qty of actions</Trans>
                            <Select
                                defaultValue={allStages.find(l => l.value == selectedStage)}
                                //isMulti
                                name="stage"
                                placeholder="Выберите этап"
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={false}
                                value={allStages.find(l => l.value == selectedStage)}
                                onChange={(e) => { setSelectedStage(e.value) }}
                                options={allStages}
                                className="select-stage-filter"
                                classNamePrefix="select-stage"
                            />
                        </div>
                        <div className='graf-container'>
                            <div className='graf-text-container'>
                                <div className='graf-header'><Trans>TOTAL</Trans></div>
                                <div className='graf-footer'>{QTYNumber.format(calcTotal(dataEtap.datasets[0].data))}</div>
                            </div>
                            <Doughnut
                                width={272}
                                height={272}
                                data={dataEtap}
                                options={options}
                            />
                        </div>
                        {dataEtap !== null && dataEtap.labels.map((legendItem, index) => {
                            const rowData = {
                                color: dataEtap.datasets[0].backgroundColor[index],
                                description: legendItem,
                                total: dataEtap.datasets[0].data[index]
                            };
                            return (
                                <LegendRow key={index} legendRowData={rowData} showWraper={dataEtap.labels.length-1 !== index} />
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatisticPage;


//<select className="select" value={selectedStage} onChange={(e) => { setSelectedStage(e.target.value) }}>
//    <option value={0}><Trans>All</Trans></option>
//    <option value={1}><Trans>Legal</Trans></option>
//    <option value={2}><Trans>Soft</Trans></option>
//    <option value={3}><Trans>Hard</Trans></option>
//    <option value={4}><Trans>System</Trans></option>
//</select>