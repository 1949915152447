import React, { useState, useEffect } from 'react';
import password from 'secure-random-password';
import { Trans, t } from "@lingui/macro";
import { useSelector } from 'react-redux';
import Select from 'react-select';

const Modal = ({ modalData, onCancel, onSave }) => {

    const { list } = useSelector(state => state.projects);

    const [user, setUser] = useState(state => {
        if (modalData !== null) {
            return modalData;
        } else {
            return {
                id: null,
                email: '',
                password: '',
                userProjectsIds: [],
                role: 1
            };
        };
    });



    const allProjects = list.map(x => ({ value: x.id, label: x.name }));

    //useEffect(() => {
    //    console.log("!!!!", user.userProjectsIds.map(x => { return allProjects.find(l => l.value == x) }));
    //    console.log(user);
    //}, [user]);


    const isSuperAdmin = user.email === 'taulean@pfbc.md' ? true : false;

    const onCloseHandler = (e) => {
        e.preventDefault();
        onCancel();
    };

    const onEditEmailHandler = ({ value }) => {
        setUser(state => { return { ...state, email: value } });
    };

    const onEditPasswordHandler = ({ value }) => {
        setUser(state => { return { ...state, password: value } });
    };

    const onGenerateHandler = (e) => {
        e.preventDefault();
        const newPassword = password.randomPassword({ characters: [password.lower, password.upper, password.digits] });
        setUser(state => { return { ...state, password: newPassword } });
    };

    const onChangeRoleHandler = ({ value }) => {
        setUser(state => { return { ...state, role: parseInt(value), userProjectsIds: (parseInt(value) === 1 ? [...state.userProjectsIds]: []) } });
    };

    const onChangeProjectsHandler = (value) => {
        //console.log(value);

        setUser(state => {
            const newState = { ...state };
            const newProjects = value.map(x => {
                return x.value;
            });
            newState.userProjectsIds = newProjects;
            return newState;
        });
    };

    return (
        <div id="modal" className="modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title"><Trans>Edit/Create user</Trans></h3>
                        <a href="#close" title={t`Close`} className="close" onClick={(e) => onCloseHandler(e)}>×</a>
                    </div>
                    <div className="modal-body">
                        <form className="form" action="">
                            <div>
                                <label>Email</label>
                                <input type="email" value={user.email} onChange={(e) => onEditEmailHandler(e.target)} name="" disabled={isSuperAdmin} />
                            </div>
                            <div>
                                <label><Trans>Password</Trans></label>
                                <div className="btn-group">
                                    <input type="text" name="" value={user.password} onChange={(e) => onEditPasswordHandler(e.target)} />
                                    <button className="btn" type="submit" onClick={onGenerateHandler}><Trans>Generate</Trans></button>
                                </div>
                            </div>
                            <div>
                                <label><Trans>Role</Trans></label>
                                <select className="select" disabled={isSuperAdmin} value={user.role} onChange={(e) => { onChangeRoleHandler(e.target) }}>
                                    <option value='0'><Trans>Administrator</Trans></option>
                                    <option value='1'><Trans>User</Trans></option>
                                </select>
                            </div>
                            {user && user.role === 1 &&
                                <div>
                                    <label><Trans>Projects</Trans></label>
                                    <Select
                                        //defaultValue={[]}
                                        isMulti
                                        name="user-projects"
                                        placeholder="Выберите проекты"
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        value={user.userProjectsIds.map(x => { return allProjects.find(l => l.value == x) })}
                                        onChange={onChangeProjectsHandler}
                                        options={allProjects}
                                        className="multi-select-projects"
                                        classNamePrefix="select-projects"
                                    />
                                </div>
                            }
                            <button className="lime" type="submit" onClick={() => onSave(user)}><Trans>Save</Trans></button>
                            <button type="reset" onClick={onCancel}><Trans>Cancel</Trans></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;