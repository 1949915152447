import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from "@lingui/macro";

import { logout } from '../../store/authSlice';

//import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import logo from '../../assets/img/logo.svg';
import search from "../../assets/img/search.svg";
import NavMenu from '../NavMenu/NavMenu';
import LanguageSelector from '../LanguageSelector/index';
//import header from "../assets/img/header.png";

const PageHeader = () => {
    const { isAuth, user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [collapsed, setCollapsed] = useState(false);
    const [mQuery, setMQuery] = useState({
        matches: window.innerWidth > 780 ? true : false,
    });

    useEffect(() => {
        let mediaQuery = window.matchMedia("(min-width: 780px)");
        mediaQuery.addListener(setMQuery);
        // this is the cleanup function to remove the listener
        return () => mediaQuery.removeListener(setMQuery);
    }, []);

    //useEffect(() => {
    //    document.addEventListener("mousedown", clickOutsideHandler);

    //    return () => {
    //        document.removeEventListener("mousedown", clickOutsideHandler);
    //    };
    //}, []);

    //const clickOutsideHandler = (e) => {
    //    //if (
    //    //    !e.target.classList.contains("custom-select-option") &&
    //    //    !e.target.classList.contains("selected-text")
    //    //) {
    //        setCollapsed(false);
    //    //};
    //};
    //useEffect(() => {
    //    console.log(mQuery.matches, collapsed);
    //    if (mQuery.matches === false) {
    //        //setCollapsed(false);
    //    };
    //}, [mQuery, setCollapsed]);

    const logoutHandler = () => {
        dispatch(logout(user));
    };

    return (
        <header>
            <div className="header-left">
                <div className="header-left_wrap">
                    <input id="menu__toggle" type="checkbox" value={collapsed} onChange={() => setCollapsed(state => !state)} />
                    <label htmlFor="menu__toggle" className="menu__btn">
                        <span></span>
                    </label>
                    {(collapsed && mQuery.matches === false) &&
                        <div className="mobile" style={{ zIndex: 1 }} >
                        <NavMenu />
                        </div>
                    }

                    <img src={logo} alt="Pfb" />
                </div>
            </div>
            <div className="header-right">
                <div className="cont">
                    <div className="title"><Trans>Personal cabinet</Trans></div>
                    <div className="lk">
                        <LanguageSelector />
                        <Link to='/' onClick={logoutHandler}><Trans>Logout</Trans></Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default PageHeader;

//<div className="search">
//    <input name="search" placeholder="Search" />
//    <button type="submit"><img src={search} alt="Search" /></button>
//</div>

////Для мобильной версии нужно изменить верстку блока будет для класса lk
//display: flex;
//flex - direction: column;
///* justify-content: flex-end; */
//align - items: baseline;


//onClickMenu={() => setCollapsed(false)} 